interface FameUser {
  name: string;
  round1: [string, number];
  round2: [string, number];
  round3: [string, number];
  round4: [string, number];
}

export const fameUsers: FameUser[] = [
  { name: 'Лучший Человек', round1: ['Лес', 0], round2: ['Координатор', 0], round3: ['Яблочный червь', 5], round4: ['Самурай', 2] },
  { name: 'Аскарон Британия', round1: ['Замок', 26], round2: ['Звёзды', 22], round3: ['Дьявольская машина', 18], round4: ['Новая жизнь', 9] },
  {
    name: 'Tamashi No-Kage',
    round1: ['Город (Погодный маг)', 2],
    round2: ['Зеркальная западня', 12],
    round3: ['не участвует', 0],
    round4: ['не участвует', 0],
  },
  { name: 'Даша Клочкова', round1: ['Про Таню и ломбард', 3], round2: ['Недоступное', 12], round3: ['не участвует', 0], round4: ['Монетка поменьше', 9] },
  { name: 'Васа Бессонова', round1: ['Абрахам и Мира', 1], round2: ['не участвует', 0], round3: ['Кровавое затмение', 0], round4: ['Ностальгия убивает', 7] },
  { name: 'Ярослав Мелин', round1: ['Теорема Эскобара', 1], round2: ['Цена магии', 10], round3: ['Очевидное', 0], round4: ['не участвует', 0] },
  { name: 'Лина Горностай', round1: ['Снежный', 0], round2: ['Студенты и завод', 1], round3: ['Замок', 0], round4: ['Чиновник', 3] },
  { name: 'Дарья Калинина', round1: ['Крылья', 8], round2: ['Крохотный комочек счастья', 2], round3: ['Неизбежное', 5], round4: ['Я знала тебя другим', 9] },
  { name: 'Алиса Ковалева', round1: ['Серая пустыня', 12], round2: ['Призывательница ', 0], round3: ['Я лежала в темноте', 12], round4: ['Клятва', 6] },
  { name: 'Vivian Kass', round1: ['Ленка', 31], round2: ['Шер Вирин', 7], round3: ['Фуфындезокси рибонуклеиновая кислота', 27], round4: ['Смыслы', 11] },
  { name: 'Анна Олейник', round1: ['Встреча на утёсе', 1], round2: ['Ледяной плен', 5], round3: ['Мышонок', 3], round4: ['не участвует', 0] },
  { name: 'Виктория Великанова', round1: ['Ямимаску', 13], round2: ['не участвует', 0], round3: ['не участвует', 0], round4: ['не участвует', 0] },
  { name: 'Софья Ферстина', round1: ['Волосы', 5], round2: ['не участвует', 0], round3: ['не участвует', 0], round4: ['не участвует', 0] },
  { name: 'Алиса Плис', round1: ['Встреча (отец)', 13], round2: ['Часы', 22], round3: ['Пан Воржишек', 20], round4: ['Наша битва будет легендарной', 10] },
  { name: 'Поля Землеройка-Кьютина', round1: ['Иштир Агаши', 4], round2: ['не участвует', 0], round3: ['Закрывая глаза', 19], round4: ['не участвует', 0] },
  { name: 'Слава Тринкет', round1: ['не участвует', 0], round2: ['Спасибо, мистер Спринг!', 3], round3: ['Найдёныш', 0], round4: ['Мама позвонила((', 8] },
  {
    name: 'Александра Никифорова',
    round1: ['не участвует', 0],
    round2: ['не участвует', 0],
    round3: ['💀 Как долго ты сможешь сопротивляться?', 0],
    round4: ['не участвует', 0],
  },
  { name: 'Олеся Пустотина', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['Калокагатия', 0], round4: ['Плата за силу', 1] },
  { name: 'Евгения Бельская', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['Я достал нож', 7], round4: ['не участвует', 0] },
  { name: 'Екатерина Лондарева', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['Чудище', 3], round4: ['Монетка побольше', 13] },
  { name: 'Light Lird', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['Дракон', 6], round4: ['Врата', 4] },
  { name: 'Инна Бойко', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['Кто угодно, но не я', 18], round4: ['Цель', 6] },
  { name: 'Sad Storyteller', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['Воля мира', 0], round4: ['Обещанная', 5] },
  { name: 'Тигра Мазурецкая (Лобанова)', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['Обоз', 1], round4: ['не участвует', 0] },
  { name: 'Кристина Епишова', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['Стена Слов', 22], round4: ['не участвует', 0] },
  { name: 'Тоня Жужужа', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['Клининг', 2], round4: ['Телохранитель', 5] },
  {
    name: 'Латышка',
    round1: ['не участвует', 0],
    round2: ['не участвует', 0],
    round3: ['не участвует', 0],
    round4: ['Сто блокнотов на пути к цветущим садам на Марсе', 30],
  },
  { name: 'Lis Hemlock', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['не участвует', 0], round4: ['У всего есть цена', 6] },
  { name: 'Иннокентий Белых', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['не участвует', 0], round4: ['не участвует', 0] },
  { name: 'Мафия Маффин', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['не участвует', 0], round4: ['не участвует', 0] },
  { name: 'Юлия Чатян (Ефимкина)', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['не участвует', 0], round4: ['не участвует', 0] },
  { name: 'Ева Фульмен', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['не участвует', 0], round4: ['не участвует', 0] },
  { name: 'Таисия Иванова', round1: ['не участвует', 0], round2: ['не участвует', 0], round3: ['не участвует', 0], round4: ['не участвует', 0] },
];
