import { ResultsState } from '../../../store/results.slice';
import { ActionType } from '../../../types/types';
import { pluralSets, pluralText } from './../../../methods/text.methods';
import { ExtraVoteBlock } from './components/ExtraVoteBlock';
import { NominationBlock } from './components/NominationBlock';
import { nomanationsList } from './Nominations';

interface Props {
  results: ResultsState;
  myStatus: ActionType;
}

export const MyResults = (props: Props) => {
  if (props.myStatus !== ActionType.GameEnded) {
    return (
      <div className="game">
        <div className="blocked">Результаты появятся, когда все проголосуют</div>
      </div>
    );
  }

  const { results } = props;
  const nominationsTotalCount = results.nominations.reduce((acc, type) => acc + parseInt(type.count), 0);

  return (
    <div className="results">
      <div>рассказ</div>
      <h2>«{results.title}»</h2>
      <div>набрал</div>
      <h2>{pluralText(results.rating, pluralSets.balls)}</h2>
      <div>и занял</div>
      <h2>{results.myPlace} место</h2>
      <div className="separator" />
      <div>За него проголосовало {pluralText(results.votesTotal, pluralSets.people)}</div>
      <div className="separator" />
      <div>получил {pluralText(results.extraVotesTotal, pluralSets.extraVotesGen)}</div>
      <div className="separator" />
      <div>{pluralText(results.reviews.length, pluralSets.reviews)}</div>
      <div className="separator" />
      <div>{pluralText(results.nominations.length, pluralSets.nominations)}</div>

      <div className="separator" />

      <div>его длина — {pluralText(results.characters, pluralSets.characters)} </div>
      <div className="separator" />
      <div>он длиннее {pluralText(results.textsShorterMine, pluralSets.textsGen)} </div>
      <div className="separator" />
      <div>и короче {pluralText(results.textsLongerMine, pluralSets.textsGen)} </div>

      <div className="statsGrid4">
        <ExtraVoteBlock type="like" extraVotes={results.extraVotes} />
        <ExtraVoteBlock type="theme" extraVotes={results.extraVotes} />
        <ExtraVoteBlock type="readable" extraVotes={results.extraVotes} />
        <ExtraVoteBlock type="style" extraVotes={results.extraVotes} />
      </div>

      <div className="separator" />

      {results.nominations.length > 0 && (
        <>
          <h2>{pluralText(nominationsTotalCount, pluralSets.nominations)}</h2>
          <div className="nominations">
            {results.nominations.length > 0 &&
              results.nominations.map(({ type, count }, index) => {
                const nomanation = nomanationsList.find((nom) => nom.type === type);
                if (nomanation) {
                  return <NominationBlock icon={nomanation.icon} title={nomanation.title} count={parseInt(count)} key={index} />;
                }
                return null;
              })}
          </div>
        </>
      )}

      <div className="separator" />

      {results.reviews.length > 0 && (
        <>
          <div className="reviews">
            <h2>{pluralText(results.reviews.length, pluralSets.reviews)}</h2>
            {results.reviews.map((review, index) => {
              const rows = review.text.split('\n');
              return (
                <div className="review" key={index}>
                  {rows.map((row, index2) => {
                    return <div key={index2}>{row}</div>;
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}

      <div className="separator" />
      <div className="separator" />

      <div>На твой текст поставили</div>
      <div>
        <span className="strong">{pluralText(props.results.betsToMe.beecoin, pluralSets.beecoin)}</span> 🐝
      </div>
      <div>
        и <span className="strong">{pluralText(props.results.betsToMe.watermeloncoin, pluralSets.watermeloncoin)}</span> 🍉
      </div>

      <div className="separator" />
      <div className="separator" />

      {results.betsSucceed.length > 0 &&
        results.betsSucceed.map((bet, index) => {
          const pluralType = bet.coin === 'beecoin' ? pluralSets.beecoin : pluralSets.watermeloncoin;
          const coinIcon = bet.coin === 'beecoin' ? '🐝' : '🍉';
          return (
            <div className="bets" key={index}>
              Ставка на текст <span className="strong">«{bet.textTitle}»</span> сработала и принесла тебе {pluralText(bet.amount * 2, pluralType)} {coinIcon}
            </div>
          );
        })}
    </div>
  );
};
