import { ExtraVoteType } from '../../../../store/profile.slice';
import { TextAdmin } from '../../../../types/types';
import style from './ExtraVoteBlock.module.scss';

interface Props {
  extraVotes: TextAdmin['extraVotes'];
  type: ExtraVoteType;
}

export const ExtraVoteBlock = (props: Props) => {
  const { extraVotes, type } = props;
  const extraVotesTotal = extraVotes[type].good + extraVotes[type].neutral + extraVotes[type].bad;

  const goodPercent = Math.round((extraVotes[type].good / extraVotesTotal) * 100);
  const neutralPercent = Math.round((extraVotes[type].neutral / extraVotesTotal) * 100);
  const badPercent = Math.round((extraVotes[type].bad / extraVotesTotal) * 100);

  interface TypeDescription {
    [key: string]: string;
  }

  const typeDescription: TypeDescription = {
    like: 'Общее впечатление',
    theme: 'Соответствие теме',
    readable: 'Читабельность',
    style: 'Красота текста',
  };

  if (extraVotesTotal === 0) {
    return (
      <div className={style.statsCell}>
        <div className={style.statTitle}>{typeDescription[type]}</div>
        <div className={style.statValue}>нет данных</div>
      </div>
    );
  }

  return (
    <div className={style.statsCell}>
      <div className={style.statTitle}>{typeDescription[type]}</div>
      <div className={style.statValue}>
        <div className={style.statsGrid3}>
          <div className={style.green}>{extraVotes[type].good}</div>
          <div className={style.yellow}>{extraVotes[type].neutral}</div>
          <div className={style.red}>{extraVotes[type].bad}</div>
        </div>
        <div className={style.statsGrid3}>
          <div className={style.green}>{goodPercent}%</div>
          <div className={style.yellow}>{neutralPercent}%</div>
          <div className={style.red}>{badPercent}%</div>
        </div>
      </div>
    </div>
  );
};
