import React from 'react';
import { ExtraVote, ProfileState } from '../../store/profile.slice';
import { ActionType, Text } from '../../types/types';
import { TextExtraVote } from './TextExtraVote';
import { postExtraVoteClear } from '../../api/user.api';
import { useParams } from 'react-router-dom';
import { getProfileMethod } from '../../methods/app.methods';

interface Props {
  text: Text;
  profile: ProfileState;
  index: number;
  badges?: ExtraVote[];
  canExtraVoting?: boolean;
  onChangeIndexCallback?: (textIndex: number) => void;
}

const badgesTypes = {
  like: {
    1: 'В целом текст не понравился',
    2: 'В целом текст средний',
    3: 'В целом текст понравился',
  },
  theme: {
    1: 'Тема раскрыта плохо',
    2: 'Тема раскрыта средне',
    3: 'Тема раскрыта хорошо',
  },
  readable: {
    1: 'Текст читается тяжело',
    2: 'Текст читается средне',
    3: 'Текст читается хорошо',
  },
  style: {
    1: 'Стиль текста не понравился',
    2: 'Стиль текста обычный',
    3: 'Стиль текста приятный',
  },
  choose: {
    1: '1 балл',
    2: '2 балла',
    3: '3 балла',
  },
};

const Badge = ({ vote }: { vote: ExtraVote }) => {
  let color = '';
  let badgeText = '';
  if (vote.value === 1) color = 'red';
  if (vote.value === 2) color = 'yellow';
  if (vote.value === 3) color = 'green';
  badgeText = badgesTypes[vote.type][vote.value];
  return <span className={`badge ${color}`}>{badgeText}</span>;
};

export const TextBlock = (props: Props) => {
  const [showExtraVote, set_showExtraVote] = React.useState(false);
  const text = props.text;
  let { guid = '' } = useParams();

  function clearExtraVotesClick() {
    postExtraVoteClear({
      guid,
      textIndex: props.index,
    }).then(() => {
      getProfileMethod(guid);
    });
  }

  function onClickReviewButton() {
    if (props.onChangeIndexCallback) {
      props.onChangeIndexCallback(props.index - 1);
    }
  }

  return (
    <div className="textBlock">
      <div className="texttitle">
        <span>«{text.title}»</span>
      </div>
      <div>
        <a href={text.link} target="_blank" rel="noopener noreferrer">
          Ссылка на текст
        </a>
      </div>
      {props.profile.status !== ActionType.GameEnded && props.canExtraVoting && !showExtraVote && !text.isMine && props.badges?.length !== 4 && (
        <button className="openExtraButton" onClick={() => set_showExtraVote(true)}>
          Выставить дополнительные оценки
        </button>
      )}
      {props.badges && (
        <div className="badges">
          {text.isMine && <span className="badge purple">Это твой текст</span>}
          {props.badges.map((vote, index) => {
            return <Badge vote={vote} key={index} />;
          })}
        </div>
      )}
      {showExtraVote && <TextExtraVote text={props.text} index={props.index} userExtraVotes={props.profile.extraVotes} />}

      {props.profile.status !== ActionType.GameEnded && props.canExtraVoting && !text.isMine && props.badges && props.badges?.length > 0 && (
        <button className="openExtraButton" onClick={clearExtraVotesClick}>
          Стереть оценки
        </button>
      )}
      {!!props.onChangeIndexCallback && !text.isMine && (
        <button className="openExtraButton" onClick={onClickReviewButton}>
          Написать рецензию
        </button>
      )}
    </div>
  );
};
