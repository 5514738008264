export interface UserAdmin {
  id: number;
  name: string;
  status: ActionType;
}

export interface Text {
  link: string;
  title: string;
  isMine: boolean;
}

export interface Rating {
  user_id: number;
  target_id: number;
  rating: number;
}

export enum ActionType {
  Greetings = 'greetings',
  AwaitName = 'await_name',
  AwaitText = 'await_text',
  AwaitStartVoting = 'await_start_voting',
  AwaitVoting = 'await_voting',
  AwaitEndVoting = 'await_end_voting',
  GameEnded = 'game_ended',
  Banned = 'banned',
}

export type ExtraVoteByType = {
  bad: number;
  neutral: number;
  good: number;
};

export interface TextAdmin extends Text {
  user_id: number;
  user: string;
  realUser: string;
  rating: number;
  status: ActionType;
  extraVotes: {
    [key: string]: ExtraVoteByType;
  };
}

export type TextResult = {
  title: string;
  link: string;
  rating: number;
};

export type Player = {
  status: ActionType;
};

export type Coin = 'beecoin' | 'watermeloncoin';
