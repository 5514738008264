import { fameUsers } from '../../../data/users';
import { pluralSets, pluralText } from '../../../methods/text.methods';
import style from './HallOfFame.module.scss';
import cn from 'classnames';

interface TextRowProps {
  cellData: [string, number];
  index: number;
  place: number;
}

const TextRow = (props: TextRowProps) => {
  return (
    <>
      {props.cellData[0] !== 'не участвует' && (
        <>
          <div className={style.textTitle}>
            Грелочка {props.index}: «{props.cellData[0]}»: {pluralText(props.cellData[1], pluralSets.balls)}
            <div
              className={cn(style.badge, {
                [style.gold]: props.place + 1 === 1,
                [style.silver]: props.place + 1 === 2,
                [style.copper]: props.place + 1 === 3,
                [style.gray]: props.place + 1 > 3,
              })}
            >
              {props.place + 1} место
            </div>
          </div>
        </>
      )}
      {props.cellData[0] === 'не участвует' && (
        <>
          <div className={style.noText}>❌ Не участвовал(а)</div>
        </>
      )}
    </>
  );
};

export const HallOfFame = () => {
  const fameUsersSorted = [...fameUsers];
  fameUsersSorted.sort(function (a, b) {
    const summA = a.round1[1] + a.round2[1] + a.round3[1] + a.round4[1];
    const summB = b.round1[1] + b.round2[1] + b.round3[1] + b.round4[1];
    if (summA > summB) return -1;
    if (summA < summB) return 1;
    return 0;
  });

  const fameUserSortedByRound1 = [...fameUsers];
  fameUserSortedByRound1.sort(function (a, b) {
    const valueA = a.round1[1];
    const valueB = b.round1[1];
    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  });

  const fameUserSortedByRound2 = [...fameUsers];
  fameUserSortedByRound2.sort(function (a, b) {
    const valueA = a.round2[1];
    const valueB = b.round2[1];
    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  });

  const fameUserSortedByRound3 = [...fameUsers];
  fameUserSortedByRound3.sort(function (a, b) {
    const valueA = a.round3[1];
    const valueB = b.round3[1];
    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  });

  const fameUserSortedByRound4 = [...fameUsers];
  fameUserSortedByRound4.sort(function (a, b) {
    const valueA = a.round4[1];
    const valueB = b.round4[1];
    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  });

  return (
    <div className={style.hallOfFame}>
      {fameUsersSorted.map((user, index) => {
        const summ = user.round1[1] + user.round2[1] + user.round3[1] + user.round4[1];
        const isVeteran =
          user.round1[0] !== 'не участвует' && user.round2[0] !== 'не участвует' && user.round3[0] !== 'не участвует' && user.round4[0] !== 'не участвует';

        const round1place = fameUserSortedByRound1.findIndex((_user) => _user.name === user.name);
        const round2place = fameUserSortedByRound2.findIndex((_user) => _user.name === user.name);
        const round3place = fameUserSortedByRound3.findIndex((_user) => _user.name === user.name);
        const round4place = fameUserSortedByRound4.findIndex((_user) => _user.name === user.name);
        return (
          <div className={style.userRow} key={index}>
            <h3>
              #{index + 1}: {user.name}
            </h3>
            {isVeteran && <div className={cn(style.badge, style.purple)}>Ветеран всех грелочек!</div>}
            <TextRow index={1} cellData={user.round1} place={round1place} />
            <TextRow index={2} cellData={user.round2} place={round2place} />
            <TextRow index={3} cellData={user.round3} place={round3place} />
            <TextRow index={4} cellData={user.round4} place={round4place} />
            <div className={style.gridCell}>Всего баллов: {summ}</div>
            <hr />
          </div>
        );
      })}
    </div>
  );
};
