import { VoteAdmin } from '../../../api/user.api';
import cn from 'classnames';
import { Player } from '../../../types/types';

interface Props {
  votes: VoteAdmin[];
  players: Player[];
}

export const Votes = (props: Props) => {
  const total = props.players.length;
  const voted = Math.round(props.votes.length / 5);

  return (
    <div className="votes">
      <div className="votesTitleBig">
        {props.votes.length} / {props.players.length * 5} голосов
      </div>
      <div className="votesTitleSmall">
        {props.votes.length / 5} / {props.players.length} игроков
      </div>
      <table>
        <thead>
          <tr>
            <td>Кто</td>
            <td>Сколько</td>
            <td>Кому</td>
            <td>Текст</td>
          </tr>
        </thead>
        <tbody>
          {props.votes.map((vote) => {
            return (
              <tr className="vote">
                <td className="name">{vote.fromUser.name}</td>
                <td>
                  <span> ➔ </span>
                  <span
                    className={cn('rating', {
                      gold: vote.rating > 2,
                      orange: vote.rating == 2,
                      red: vote.rating == 1,
                    })}
                  >
                    {vote.rating}
                  </span>
                  <span> ➔ </span>
                </td>
                <td className="name">{vote.toUser.name}</td>
                <td>«{vote.toUser.textName}»</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
