import React from 'react';
import style from './Bets.module.scss';
import { Text } from '../../../types/types';
import { ProfileState } from '../../../store/profile.slice';
import { pluralSets, pluralText } from '../../../methods/text.methods';
import { postBetApi } from '../../../api/user.api';
import { getProfileMethod } from '../../../methods/app.methods';
import { useParams } from 'react-router-dom';

interface Props {
  texts: Text[];
  profile: ProfileState;
}

export const Bets = (props: Props) => {
  let { guid = '' } = useParams();
  const [textIndex, set_textIndex] = React.useState<string>('0');
  const [amount, set_amount] = React.useState<number>(0);
  const [coin, set_coin] = React.useState<'beecoin' | 'watermeloncoin'>('beecoin');
  const [isReadyToBet, set_isReadyToBet] = React.useState(false);

  function onChangeTextOption(value: string) {
    if (value === '0') {
      set_textIndex('0');
      set_isReadyToBet(false);
    } else {
      set_textIndex(value);
      set_isReadyToBet(true);
    }
  }

  function onChangeCoinOption(value: string) {
    if (value === 'beecoin') {
      set_coin('beecoin');
    }
    if (value === 'watermeloncoin') {
      set_coin('watermeloncoin');
    }
  }

  function onPressBetButton() {
    if (textIndex === '0') return;
    if (amount === 0) return;
    if (coin === 'beecoin' && amount > props.profile.balance.beecoins) return;
    if (coin === 'watermeloncoin' && amount > props.profile.balance.watermeloncoins) return;

    const dataToSend = {
      guid,
      textIndex: parseInt(textIndex),
      amount,
      coin,
    };
    postBetApi(dataToSend)
      .then(() => {
        getProfileMethod(guid);
      })
      .catch(() => alert('Ошибка. Не удалось сделать ставку'));
  }

  const isShowTotalizator = props.profile.balance.beecoins > 0 || props.profile.balance.watermeloncoins > 0;

  return (
    <div className={style.bets}>
      <h3>Твой грелкокошлёк:</h3>

      <div className={style.balance}>
        <div className={style.coinblock}>
          <img src="/img/bee_coin.png" className={style.coinIcon} alt="bee_coin" />
          <span className={style.coinAmount}>{pluralText(props.profile.balance.beecoins, pluralSets.beecoin)}</span>
        </div>
        <div className={style.coinblock}>
          <img src="/img/watermelon_coin.png" className={style.coinIcon} alt="watermelon_coin" />
          <span className={style.coinAmount}>{pluralText(props.profile.balance.watermeloncoins, pluralSets.watermeloncoin)}</span>
        </div>
      </div>

      {isShowTotalizator && (
        <div className={style.totalizator}>
          <span>Поставить на текст</span>
          <select className={style.selectText} onChange={(event) => onChangeTextOption(event.target.value)}>
            <option value={0}>❌ Не выбрано</option>
            {props.texts.map((text, index2) => {
              return (
                <option key={index2} value={index2 + 1}>
                  {text.title}
                </option>
              );
            })}
          </select>

          <input type="number" value={amount} min={0} onChange={(event) => set_amount(parseInt(event.target.value))} />

          <select className={style.selectCoin} onChange={(event) => onChangeCoinOption(event.target.value)}>
            <option value={0}>❌ Не выбрано</option>
            {props.profile.balance.beecoins > 0 && <option value={'beecoin'}>🐝 Пчелокоинов</option>}
            {props.profile.balance.watermeloncoins > 0 && <option value={'watermeloncoin'}>🍉 Арбузокоинов</option>}
          </select>

          <button disabled={!isReadyToBet} onClick={onPressBetButton}>
            Поставить!
          </button>
        </div>
      )}

      <div className={style.suggestedBets}>
        {props.profile.bets.map((bet) => {
          const pluralType = bet.coin === 'beecoin' ? pluralSets.beecoin : pluralSets.watermeloncoin;
          const coinIcon = bet.coin === 'beecoin' ? '🐝' : '🍉';
          const text = props.texts[bet.textIndex - 1];
          if (!text) return null;
          return (
            <div className={style.bet}>
              Ты поставил {pluralText(bet.amount, pluralType)} {coinIcon} на текст <span className="strong">«{text.title}»</span>
            </div>
          );
        })}
      </div>

      {/* <div>
        <div>textIndex: {String(textIndex)}</div>
        <div>amount: {String(amount)}</div>
        <div>coin: {String(coin)}</div>
        <div>isReadyToBet: {String(isReadyToBet)}</div>
      </div> */}
    </div>
  );
};
