import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Player, TextResult } from '../types/types';

export interface GameState {
  players: Player[];
  results: TextResult[];
}

const initialState: GameState = {
  players: [],
  results: [],
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setPlayers: (state, action: PayloadAction<GameState>) => {
      state.players = action.payload.players;
      state.results = action.payload.results;
    },
  },
});
