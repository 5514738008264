import React from 'react';
import { ExtraVoteBlock } from './components/ExtraVoteBlock';
import style from './Rules.module.scss';
import { nomanationsList } from './Nominations';
import { NominationBlock } from './components/NominationBlock';

interface Props {}

export const Rules = (props: Props) => {
  const extraVoteMock = {
    like: {
      bad: 4,
      neutral: 2,
      good: 1,
    },
    theme: {
      bad: 4,
      neutral: 2,
      good: 1,
    },
    readable: {
      bad: 4,
      neutral: 2,
      good: 1,
    },
    style: {
      bad: 4,
      neutral: 2,
      good: 1,
    },
  };
  return (
    <>
      <div className={style.rules}>
        <div className={style.line}>Мы собираемся все вместе и пишем небольшие рассказы по своему сеттингу по заранее выбранной теме.</div>
        <div className={style.line}>
          Важный момент - наличие телеграма, так как тексты нужно сдавать при помощи бота для обеспечения анонимности и проведения голосования. В самом боте
          есть все инструкции.{' '}
          <a href="https://t.me/gralka_bot" target="_blank" rel="noreferrer">
            https://t.me/gralka_bot
          </a>
        </div>

        <h3>Расписание</h3>

        <table>
          <thead>
            <tr>
              <td>Когда</td>
              <td>Сколько</td>
              <td>Что делаем</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>16 - 17 ноября</td>
              <td>2 дня</td>
              <td>Выбор темы</td>
            </tr>
            <tr>
              <td>18 ноября - 27 ноября</td>
              <td>10 дней</td>
              <td>Написание текстов</td>
            </tr>
            <tr>
              <td>28 ноября - 30 ноября</td>
              <td>4 дня</td>
              <td>Чтение и голосование</td>
            </tr>
            <tr>
              <td>1 декабря</td>
              <td></td>
              <td>Результаты</td>
            </tr>
          </tbody>
        </table>

        <h3>Правила</h3>

        <div className={style.line}>
          ★ Тексты сдаются через бота в телеграме общедоступной ссылкой на гугл-документы. Ссылки на яндекс-диск и другие сервисы не принимаются. Все ссылки
          будут проверены, и если они не работают, вам об этом скажут
        </div>

        <div className={style.line}>
          ★ В качестве имени в боте можно использовать реальное имя или выдуманное, если вы хотите сохранить интригу после объявления результатов
        </div>

        <div className={style.line}>★ Текст должен быть написан специально для движа по любому вашему сеттингу, ранее написанные тексты не принимаются</div>

        <div className={style.line}>★ Измените имена и названия в тексте для сохранения анонимности</div>

        <div className={style.line}>★ Желательно, чтобы у текста было любое, хотя бы самое простое название, не совпадающее с темой</div>

        <div className={style.line}>★ Объем текста от 1000 до 10 000 знаков (с учётом пробелов)</div>

        <div className={style.line}>★ В тексте не должно быть материалов 18+ и материалов, нарушающих действующее законодательство</div>

        <div className={style.line}>
          ★ Не запрещены оригинальные шрифты, оформление текста, в том числе иллюстрациями, но оценка должна выставляться только за содержание текста
        </div>

        <div className={style.line}>
          ★ На этапе голосования вы должны указать 5 номеров понравившихся текстов. Нужно помнить, что их порядок имеет значение. Первый текст получает больше
          баллов, чем второй и третий
        </div>

        <div className={style.line}>★ До окончания движа нужно сохранять анонимность</div>

        <div className={style.line}>★ Пропустившие сдачу работ будут исключены из движа и не получат свои результаты</div>

        <div className={style.line}>★ Пропустившие голосование будут исключены из движа и не получат свои результаты</div>

        <h3>Этапы игры</h3>

        <div className={style.line}>1. Зарегистрироваться и выбрать имя</div>
        <div className={style.line}>2. Написать тексты и сдать их при помощи бота</div>
        <div className={style.line}>3. Выбрать 5 текстов, которые получат баллы</div>
        <div className={style.line}>4. [дополнительно] Выставить дополнительные оценки на странице "Тексты"</div>
        <div className={style.line}>5. [дополнительно] Выбрать номинации на странице "Номинации"</div>
        <div className={style.line}>6. [дополнительно] Написать рецензии на странице "Тексты"</div>
        <div className={style.line}>7. [дополнительно] Поставить пчол 🐝 и арбузы 🍉 на победителей на странице "Ставки"</div>
        <div className={style.line}>8. Ждать результатов</div>

        <h3>Дополнительные оценки</h3>

        <div className={style.line}>Есть 4 вида шкал для дополнительных оценок:</div>
        <div className={style.line}>★ Понравился ли текст в целом?</div>
        <div className={style.line}>★ Хорошо ли раскрыта тема?</div>
        <div className={style.line}>★ Легко ли читается текст?</div>
        <div className={style.line}>★ Понравился ли стиль текста?</div>

        <div className={style.line}>Все они будут отражены в результатах в виде конкретного количества и процентного соотношения</div>

        <div className={style.grid4}>
          <ExtraVoteBlock type="like" extraVotes={extraVoteMock} />
          <ExtraVoteBlock type="theme" extraVotes={extraVoteMock} />
          <ExtraVoteBlock type="readable" extraVotes={extraVoteMock} />
          <ExtraVoteBlock type="style" extraVotes={extraVoteMock} />
        </div>

        <h3>Номинации</h3>

        <div className={style.line}>
          В игре есть {nomanationsList.length} видов номинаций. Ты можешь выдать номинацию для любого текста. Можно дать одному тексту несколько номинаций, но
          нельзя дать несколько одинаковых номинаций.
        </div>
        <div className={style.nominations}>
          {nomanationsList.map((nomination, index) => {
            return <NominationBlock key={index} icon={nomination.icon} title={nomination.title} count={0} />;
          })}
        </div>

        <div className={style.line}>★ Стендап — Самый смешной текст</div>
        <div className={style.line}>★ Драматург — Самый драматичный текст</div>
        <div className={style.line}>★ Филолог — Самые необычные словесные обороты</div>
        <div className={style.line}>★ Мимими — Самый милый текст :3</div>
        <div className={style.line}>★ Шеф-повар — Текст, в котором было много еды</div>
        <div className={style.line}>★ Ого! — Текст, в котором было что-то очень неожиданное</div>
        <div className={style.line}>★ Мир ещё не готов — Самый недопонятый текст</div>
        <div className={style.line}>★ В лоб — Буквальная интерпретация темы</div>
        <div className={style.line}>★ Глубоко — Неочевидная интерпретация темы</div>
        <div className={style.line}>★ Нормис — Самый нормальный текст. Что бы это значило?</div>
        <div className={style.line}>★ Воин света — Самый светлый текст</div>
        <div className={style.line}>★ Воин тьмы — Самый мрачный текст</div>
      </div>
    </>
  );
};
