import React from 'react';

export const Index = () => {
  return (
    <div className="indexPage">
      <div>
        Чтобы войти в профиль, <br /> нужен уникальный id
      </div>
    </div>
  );
};
