import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Coin, ExtraVoteByType } from '../types/types';
import { NominationType } from './profile.slice';

export type Rate = 'good' | 'bad' | 'neutral';

export type ExtraVoteValue = 1 | 2 | 3;
export type ExtraVoteType = 'theme' | 'like' | 'readable' | 'style' | 'choose';

export interface ExtraVote {
  type: ExtraVoteType;
  value: ExtraVoteValue;
  textIndex: number;
}
export interface NominationTypeForMe {
  type: NominationType;
  count: string;
}

export interface ResultsState {
  title: string;
  characters: number;
  textsShorterMine: number;
  textsLongerMine: number;
  rating: number;
  myPlace: number;
  votesTotal: number;
  extraVotesTotal: number;
  extraVotes: {
    [key: string]: ExtraVoteByType;
  };
  nominations: NominationTypeForMe[];
  reviews: { text: string }[];
  betsSucceed: {
    amount: number;
    coin: Coin;
    textTitle: string;
  }[];
  betsToMe: {
    beecoin: number;
    watermeloncoin: number;
  };
}

const initialState: ResultsState = {
  title: '',
  characters: 0,
  textsShorterMine: 0,
  textsLongerMine: 0,
  rating: 0,
  myPlace: 0,
  votesTotal: 0,
  extraVotesTotal: 0,
  extraVotes: {
    like: {
      bad: 0,
      neutral: 1,
      good: 3,
    },
    theme: {
      bad: 1,
      neutral: 1,
      good: 3,
    },
    readable: {
      bad: 0,
      neutral: 1,
      good: 4,
    },
    style: {
      bad: 0,
      neutral: 2,
      good: 3,
    },
  },
  nominations: [],
  reviews: [],
  betsSucceed: [],
  betsToMe: {
    beecoin: 0,
    watermeloncoin: 0,
  },
};

export const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    setResults: (state, action: PayloadAction<ResultsState>) => {
      state.title = action.payload.title;
      state.characters = action.payload.characters;
      state.textsShorterMine = action.payload.textsShorterMine;
      state.textsLongerMine = action.payload.textsLongerMine;
      state.rating = action.payload.rating;
      state.myPlace = action.payload.myPlace;
      state.votesTotal = action.payload.votesTotal;
      state.extraVotesTotal = action.payload.extraVotesTotal;
      state.extraVotes = action.payload.extraVotes;
      state.nominations = action.payload.nominations;
      state.reviews = action.payload.reviews;
      state.betsSucceed = action.payload.betsSucceed;
      state.betsToMe = action.payload.betsToMe;
    },
  },
});
