import {
  getPlayersByGuidApi,
  getProfileByGuidApi,
  getResultsByGuidApi,
  getTextsAdminByGuidApi,
  getTextsByGuidApi,
  getUsersByGuidApi,
  getVotesByGuidApi,
} from '../api/user.api';
import { adminSlice } from '../store/admin.slice';
import { gameSlice } from '../store/game.slice';
import { profileSlice } from '../store/profile.slice';
import { resultsSlice } from '../store/results.slice';
import { grelkaStore } from '../store/store';
import { textsSlice } from '../store/texts.slice';

export function getProfileMethod(guid: string) {
  getProfileByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(profileSlice.actions.setProfile(res.data));
    })
    .catch(() => {
      window.location.href = '/';
    });
}

export function getTextsMethod(guid: string) {
  getTextsByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(textsSlice.actions.setList(res.data));
    })
    .catch(() => {
      window.location.href = '/';
    });
}

export function getPlayersMethod(guid: string) {
  getPlayersByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(gameSlice.actions.setPlayers(res.data));
    })
    .catch(() => {
      window.location.href = '/';
    });
}

export function getResultsMethod(guid: string) {
  getResultsByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(resultsSlice.actions.setResults(res.data));
    })
    .catch(() => {
      console.log(':)');
    });
}

export function getVotesAdminMethod(guid: string) {
  getVotesByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(adminSlice.actions.setVotes(res.data));
    })
    .catch((error) => {
      console.log(':)');
    });
}

export function getUsersAdminMethod(guid: string) {
  getUsersByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(adminSlice.actions.setUsers(res.data));
    })
    .catch((error) => {
      console.log(':)');
    });
}

export function getTextsAdminMethod(guid: string) {
  getTextsAdminByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(adminSlice.actions.setTextsAdmin(res.data));
    })
    .catch((error) => {
      console.log(':)');
    });
}
