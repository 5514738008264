import { useParams } from 'react-router-dom';
import { GrelkaState } from '../../store/store';
import { useOnload } from '../../hooks/useOnload';
import { ProfileState } from '../../store/profile.slice';
import { connect } from 'react-redux';
import { Texts } from '../texts/Texts';
import { Tabs } from '../ui/Tabs';
import { Tab } from '../ui/Tab';
import { Profile } from './user/Profile';
import { ActionType, Text, TextAdmin, UserAdmin } from '../../types/types';
import { GameState } from '../../store/game.slice';
import { VoteAdmin } from '../../api/user.api';
import { Game } from './user/Game';
import { Settings } from './user/Settings';
import { Rules } from './user/Rules';
import {
  getPlayersMethod,
  getProfileMethod,
  getResultsMethod,
  getTextsAdminMethod,
  getTextsMethod,
  getUsersAdminMethod,
  getVotesAdminMethod,
} from '../../methods/app.methods';
import { Votes } from './user/Votes';
import { Ranking } from './user/Ranking';
import { TextsAdmin } from './user/TextsAdmin';
import { MyResults } from './user/Results';
import { ResultsState } from '../../store/results.slice';
import { Nominations } from './user/Nominations';
import { Bets } from './user/Bets';
import { HallOfFame } from './user/HallOfFame';

interface Props {
  texts: Text[];
  profile: ProfileState;
  gameState: GameState;
  votes: VoteAdmin[];
  users: UserAdmin[];
  textsAdmin: TextAdmin[];
  results: ResultsState;
}

export const UserPageComponent = (props: Props) => {
  let { guid = '' } = useParams();

  useOnload(() => {
    getProfileMethod(guid);
    getTextsMethod(guid);
    getPlayersMethod(guid);
    getResultsMethod(guid);
    getVotesAdminMethod(guid);
    getUsersAdminMethod(guid);
    getTextsAdminMethod(guid);
  });

  if (!props.profile.name) {
    return null;
  }

  const tabs = [
    { name: 'bio', title: 'Мой профиль' },
    { name: 'rules', title: 'Правила' },
    { name: 'texts', title: 'Тексты' },
    { name: 'game', title: 'Игра' },
  ];
  if (props.profile.status !== ActionType.GameEnded) {
    tabs.push({ name: 'nominations', title: 'Номинации' });
  }
  tabs.push({ name: 'results', title: 'Мои результаты' });
  if (props.profile.status !== ActionType.GameEnded) {
    tabs.push({ name: 'bets', title: 'Ставки' });
  }
  tabs.push({ name: 'settings', title: 'Настройки' });
  tabs.push({ name: 'halloffame', title: 'Зал славы' });

  if (props.votes.length > 0) {
    tabs.push({ name: 'votes', title: '★ Голоса' });
    tabs.push({ name: 'chart', title: '★ График' });
  }
  if (props.textsAdmin.length > 0) {
    tabs.push({ name: 'textsAdmin', title: '★ Тексты (админ)' });
  }

  return (
    <div className="profile">
      <h2>Привет, {props.profile.name}!</h2>
      <Tabs tabs={tabs}>
        <Tab name="bio">
          <Profile texts={props.texts} profile={props.profile} />
        </Tab>
        <Tab name="rules">
          <Rules />
        </Tab>
        <Tab name="texts">
          <Texts texts={props.texts} profile={props.profile} />
        </Tab>
        <Tab name="game">
          <Game players={props.gameState.players} myStatus={props.profile.status} results={props.gameState.results} />
        </Tab>
        <Tab name="nominations">
          <Nominations profile={props.profile} texts={props.texts} />
        </Tab>
        <Tab name="results">
          <MyResults myStatus={props.profile.status} results={props.results} />
        </Tab>
        <Tab name="bets">
          <Bets profile={props.profile} texts={props.texts} />
        </Tab>
        <Tab name="settings">
          <Settings />
        </Tab>
        <Tab name="halloffame">
          <HallOfFame />
        </Tab>
        <Tab name="votes">
          <Votes votes={props.votes} players={props.gameState.players} />
        </Tab>
        <Tab name="chart">
          <Ranking votes={props.votes} users={props.users} />
        </Tab>
        <Tab name="textsAdmin">
          <TextsAdmin textsAdmin={props.textsAdmin} />
        </Tab>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state: GrelkaState) => {
  return {
    profile: state.profile,
    texts: state.texts.list,
    gameState: state.game,
    votes: state.admin.votes,
    users: state.admin.users,
    textsAdmin: state.admin.textsAdmin,
    results: state.results,
  };
};

export const UserPage = connect(mapStateToProps, {})(UserPageComponent);
