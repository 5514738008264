import style from './NominationBlock.module.scss';

interface Props {
  icon: string;
  title: string;
  count: number;
}

export const NominationBlock = (props: Props) => {
  return (
    <div className={style.nomination}>
      <div className={style.icon}>{props.icon}</div>
      <div className={style.title}>{props.title}</div>
      <div className={style.count}>{props.count > 1 ? `✖${props.count}` : ''}</div>
    </div>
  );
};
