import React from 'react';

interface Props {}

export const Settings = (props: Props) => {
  var storedTheme = localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

  const [theme, set_theme] = React.useState(storedTheme);

  function toggleTheme(theme: string) {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
    set_theme(theme);
  }

  return (
    <>
      <div className="settings">
        <div className="settingsBlock">
          <h3>Тема</h3>
          <div className="buttons">
            <div className="buttonWrapper">
              <button className="light" onClick={() => toggleTheme('light')}>
                светлая
              </button>
              <div className="text">{theme === 'light' && 'Выбрано'} </div>
            </div>
            <div className="buttonWrapper">
              <button className="dark" onClick={() => toggleTheme('dark')}>
                тёмная
              </button>
              <div className="text">{theme === 'dark' && 'Выбрано'} </div>
            </div>
            <div className="buttonWrapper">
              <button className="bee" onClick={() => toggleTheme('bee')}>
                пчола
              </button>
              <div className="text">{theme === 'bee' && 'Выбрано'} </div>
            </div>
            <div className="buttonWrapper">
              <button className="watermelon" onClick={() => toggleTheme('watermelon')}>
                арбуз
              </button>
              <div className="text">{theme === 'watermelon' && 'Выбрано'} </div>
            </div>
          </div>

          <div className="footer">
            <div className="text">
              Вжух!
              <br />
              Заходи{' '}
              <a href="https://shining-project.ru/" target="_blank" rel="noreferrer">
                на огонёк
              </a>
            </div>
            <img src="/img/iyra.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
